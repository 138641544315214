import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../assets/logo.png'; // Make sure to add your logo image
import {
  StyledHeader,
  Container,
  LogoWrapper,
  Logo,
  Nav,
  NavLink,
  ButtonGroup,
  LoginButton,
  CTAButton
} from './Header.styles';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const heroHeight = document.querySelector('.hero').offsetHeight;
      if (window.scrollY > heroHeight - 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap" rel="stylesheet" />
      </Helmet>
      <StyledHeader isScrolled={isScrolled}>
        <Container>
          <LogoWrapper isVisible={isScrolled}>
            <Logo src={logo} alt="Doldadress.se Logo" />
          </LogoWrapper>
          <Nav>
            <NavLink to="/" isScrolled={isScrolled}>Hem</NavLink>
            <NavLink to="/about" isScrolled={isScrolled}>Om oss</NavLink>
            <NavLink to="/services" isScrolled={isScrolled}>Tjänster</NavLink>
            <NavLink to="/contact" isScrolled={isScrolled}>Kontakt</NavLink>
          </Nav>
          <ButtonGroup>
            <LoginButton 
              as="a" 
              href="https://app.exti.se" 
              target="_blank" 
              rel="noopener noreferrer" 
              isScrolled={isScrolled}
            >
              Logga in
            </LoginButton>
            <CTAButton>Kom igång</CTAButton>
          </ButtonGroup>
        </Container>
      </StyledHeader>
    </>
  );
}

export default Header;

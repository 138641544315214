import React from 'react';

function Services() {
  return (
    <div>
      <h1>Våra tjänster</h1>
      <ul>
        <li>Dold adress</li>
        <li>Identitetsskydd</li>
        <li>Säker kommunikation</li>
      </ul>
    </div>
  );
}

export default Services;

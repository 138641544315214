import React from 'react';
import { 
  CardsSection, 
  CardsContainer, 
  Card, 
  CardTitle, 
  CardText 
} from './FeatureCards.styles';

function FeatureCards() {
  return (
    <CardsSection>
      <CardsContainer>
        <Card>
          <CardTitle>Säker identitet</CardTitle>
          <CardText>Skydda din personliga information med våra avancerade krypteringsmetoder och säkerhetsprotokoll.</CardText>
        </Card>
        <Card>
          <CardTitle>Anonym surfning</CardTitle>
          <CardText>Surfa på nätet utan att lämna spår. Vår teknik döljer din IP-adress och surfhistorik.</CardText>
        </Card>
        <Card>
          <CardTitle>Dataskydd</CardTitle>
          <CardText>Håll dina filer och dokument säkra med vår molnbaserade lagring och end-to-end kryptering.</CardText>
        </Card>
      </CardsContainer>
    </CardsSection>
  );
}

export default FeatureCards;

import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Kontakta oss</h1>
      <form>
        {/* Add your contact form fields here */}
      </form>
    </div>
  );
}

export default Contact;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  z-index: 1000;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.isScrolled ? 'rgba(255, 255, 255, 0.9)' : 'transparent'};
  backdrop-filter: ${props => props.isScrolled ? 'blur(10px)' : 'none'};
  z-index: 1000;
  padding: 1rem 0;
  box-shadow: ${props => props.isScrolled ? '0 2px 4px rgba(0,0,0,0.1)' : 'none'};
  transition: all 0.3s ease;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 2rem;
`;

export const LogoWrapper = styled.div`
  justify-self: start;
  display: flex;
  align-items: center;
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: opacity 0.3s ease;
`;

export const Logo = styled.img`
  height: 60px;
  width: auto;
`;

export const Nav = styled.nav`
  justify-self: center;
  display: flex;
  gap: 2rem;
`;

export const NavLink = styled(Link)`
  font-family: 'Inter', sans-serif;
  color: ${props => props.isScrolled ? '#333' : '#fff'};
  font-weight: 500;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #0070c9;
  }
`;

export const ButtonGroup = styled.div`
  justify-self: end;
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const LoginButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${props => props.isScrolled ? '#333' : '#fff'};
  color: ${props => props.isScrolled ? '#333' : '#fff'};
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: ${props => props.isScrolled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.2)'};
  }
`;

export const CTAButton = styled(Button)`
  background-color: #0070c9;
  border: none;
  color: #fff;

  &:hover {
    background-color: #005ea3;
  }
`;

export const MobileMenu = styled.div`
  // ... existing styles
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
`;

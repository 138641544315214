import styled, { keyframes } from 'styled-components';

// Define the animation
const moveRight = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

export const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

export const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.3) 70%,
    rgba(245, 245, 247, 1) 100%  // Match this color with your FeatureCards background
  );
  z-index: 1;
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 0 3rem;
`;

export const TextContent = styled.div`
  max-width: 600px;
  margin-left: 10%; // This will move the content slightly to the right
`;

export const HeroTitle = styled.h1`
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.1;
  letter-spacing: -0.02em;
`;

export const HeroText = styled.p`
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
`;

export const CTAButton = styled.button`
  display: inline-block;
  background-color: #ff0000;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #cc0000;
    transform: scale(1.05);
  }

  span {
    margin-left: 0.5rem;
    display: inline-block;
    transition: transform 0.3s ease;
  }

  &:hover span {
    animation: ${moveRight} 0.8s ease infinite;
  }
`;

export const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

import React from 'react';

function About() {
  return (
    <div>
      <h1>Om oss</h1>
      <p>Här kan du lägga till information om doldadress.se och dess syfte.</p>
    </div>
  );
}

export default About;

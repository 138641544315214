import React from 'react';
import {
  HeroSection,
  HeroOverlay,
  HeroImage,
  HeroContent,
  TextContent,
  HeroTitle,
  HeroText,
  CTAButton
} from './Hero.styles';
import heroImage from '../../assets/hero-image.jpg';
import logo from '../../assets/logo.png';
import styled from 'styled-components';

const HeroLogo = styled.img`
  height: 100px;
  margin-bottom: 2.5rem;
`;

function Hero() {
  return (
    <HeroSection className="hero">
      <HeroImage src={heroImage} alt="Säker online närvaro" />
      <HeroOverlay />
      <HeroContent>
        <TextContent>
          <HeroLogo src={logo} alt="Doldadress.se Logo" />
          <HeroTitle>Dölj dina personliga uppgifter online</HeroTitle>
          <HeroText>
            Skydda din integritet och säkerhet på nätet med våra avancerade lösningar för att dölja dina personliga uppgifter.
          </HeroText>
          <CTAButton>
            Kom igång <span>&rarr;</span>
          </CTAButton>
        </TextContent>
      </HeroContent>
    </HeroSection>
  );
}

export default Hero;

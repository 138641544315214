import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 4rem 0;
  background-color: #f8f9fa;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 20px;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`;

const CardHeader = styled.div`
  background-color: ${props => props.color};
  color: white;
  padding: 1.5rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 1px;
`;

const CardContent = styled.div`
  padding: 2rem;
`;

const Duration = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #333;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
`;

const Price = styled.div`
  font-size: 3rem;
  font-weight: 700;
  color: ${props => props.color};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: baseline;
`;

const PerMonth = styled.span`
  font-size: 1rem;
  color: #666;
  margin-left: 0.5rem;
`;

const Savings = styled.span`
  background-color: ${props => props.color};
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 1rem;
  align-self: center;
`;

const TotalPrice = styled.div`
  font-size: 1rem;
  color: #999;
  text-decoration: line-through;
  margin-bottom: 1.5rem;
`;

const Button = styled.button`
  background-color: ${props => props.color};
  color: white;
  border: none;
  border-radius: 30px;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1.5rem;
  width: 100%;

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
`;

const Feature = styled.li`
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #444;

  &:before {
    content: "✓";
    color: ${props => props.color};
    margin-right: 0.75rem;
    font-weight: bold;
  }
`;

function PricingCard({ color, header, duration, subtitle, price, savings, totalPrice, features }) {
  return (
    <Card>
      <CardHeader color={color}>{header}</CardHeader>
      <CardContent>
        <Duration>{duration}</Duration>
        <Subtitle>{subtitle}</Subtitle>
        <Price color={color}>
          {price} <PerMonth>kr / mån</PerMonth>
          {savings && <Savings color={color}>{savings}</Savings>}
        </Price>
        <TotalPrice>{totalPrice}</TotalPrice>
        <Button color={color}>Välj →</Button>
        <FeatureList>
          {features.map((feature, index) => (
            <Feature key={index} color={color}>{feature}</Feature>
          ))}
        </FeatureList>
      </CardContent>
    </Card>
  );
}

function PricingSection() {
  return (
    <Section>
      <Container>
        <PricingCard
          color="#3498db"
          header="Prova på"
          duration="3 mån"
          subtitle="Totalt skydd i 3 mån"
          price="65"
          totalPrice="195 kr"
          features={[
            "Fri Avindexering för Upplysningar",
            "Dolda Personuppgifter",
            "Adresslarm",
            "Ständig Övervakning",
            "Exklusiva Medlemserbjudanden",
            "365 Kundsupport"
          ]}
        />
        <PricingCard
          color="#e74c3c"
          header="Mest Populär"
          duration="6 mån"
          subtitle="Totalt skydd i 6 mån"
          price="49"
          savings="Spara 25%"
          totalPrice="294 kr"
          features={[
            "+2 Länkar för Avindexering",
            "Fri Avindexering för Upplysningar",
            "Dolda Personuppgifter",
            "Adresslarm",
            "Ständig Övervakning",
            "Exklusiva Medlemserbjudanden",
            "365 Kundsupport"
          ]}
        />
        <PricingCard
          color="#2ecc71"
          header="Bäst Värde"
          duration="1 år"
          subtitle="Totalt skydd i 1 år"
          price="33"
          savings="Spara 50%"
          totalPrice="396 kr"
          features={[
            "+4 Länkar för Avindexering",
            "Fri Avindexering för Upplysningar",
            "Dolda Personuppgifter",
            "Adresslarm",
            "Ständig Övervakning",
            "Exklusiva Medlemserbjudanden",
            "365 Kundsupport"
          ]}
        />
      </Container>
    </Section>
  );
}

export default PricingSection;

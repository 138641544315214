import React from 'react';
import Hero from '../components/Hero/Hero';
import FeatureCards from '../components/FeatureCards/FeatureCards';
import PricingSection from '../components/PricingSection/PricingSection';

function Home() {
  return (
    <>
      <Hero />
      <FeatureCards />
      <PricingSection />
      {/* Add more sections as needed */}
    </>
  );
}

export default Home;
